import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/header";
import Layout from "../../components/layout";
import "./style.css";
import WithProviders from "../../components/WithProviders";
import { Message } from "../../lib/getMessage";

const PrivacyPolicy = (props) => {
  const { language, updateLanguage, direction } = props;
  return (
    <Layout direction={direction} language={language} location={props.location}>
      <div>
        <Helmet title="Privacy Policy Zopsmart" />
        <Header
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="privacy-policy">
          <h2>
            <Message dictKey="footer.privacy.policy" />
          </h2>
          <p>
            <Message dictKey="privacy.policy.p1" />
          </p>
          <p>
            <Message dictKey="privacy.policy.p2" />
          </p>
          <h3>
            <Message dictKey="info.we.collect" />
          </h3>
          <p>
            <Message dictKey="info.we.collect.p1" />
          </p>
          <h4>
            <Message dictKey="log.data" />
          </h4>
          <p>
            <Message dictKey="log.data.p1" />
          </p>
          <p>
            <Message dictKey="log.data.p2" />
          </p>
          <h4>
            <Message dictKey="personal.info" />
          </h4>
          <p>
            <Message dictKey="personal.info.p1" />
          </p>
          <ul>
            <li>
              <Message dictKey="personal.info.p1.li1" />
            </li>
            <li>
              <Message dictKey="personal.info.p1.li2" />
            </li>
            <li>
              <Message dictKey="personal.info.p1.li3" />
            </li>
          </ul>
          <h4>
            <Message dictKey="reasons.for.processing.personal.info" />
          </h4>
          <p>
            <Message dictKey="reasons.for.processing.personal.info.p1" />
          </p>
          <h4>
            <Message dictKey="collection.use.info" />
          </h4>
          <p>
            <Message dictKey="collection.use.info.p1" />
          </p>
          <ul>
            <li>
              <Message dictKey="collection.use.info.p1.li1" />
            </li>
            <li>
              <Message dictKey="collection.use.info.p1.li2" />
            </li>
            <li>
              <Message dictKey="collection.use.info.p1.li3" />
            </li>
          </ul>
          <p>
            <Message dictKey="collection.use.info.p2" />
          </p>

          <ul>
            <li>
              <Message dictKey="collection.use.info.p2.li1" />
            </li>
          </ul>
          <p>
            <Message dictKey="collection.use.info.p3" />
          </p>
          <h4>
            <Message dictKey="security.your.personal.info" />
          </h4>
          <p>
            <Message dictKey="security.your.personal.info.p1" />
          </p>
          <p>
            <Message dictKey="security.your.personal.info.p2" />
          </p>
          <p>
            <Message dictKey="security.your.personal.info.p3" />
          </p>
          <h4>
            <Message dictKey="how.log.keep.personal.info" />
          </h4>
          <p>
            <Message dictKey="how.log.keep.personal.info.p1" />
          </p>
          <p>
            <Message dictKey="how.log.keep.personal.info.p2" />
          </p>
          <h3>
            <Message dictKey="children.privacy" />
          </h3>
          <p>
            <Message dictKey="children.privacy.p1" />
          </p>
          <h3>
            <Message dictKey="disclose.personal.info.to.third.party" />
          </h3>
          <p>
            <Message dictKey="disclose.personal.info.to.third.party.p1" />
          </p>
          <ul>
            <li>
              <Message dictKey="disclose.personal.info.to.third.party.p1.li1" />
            </li>
            <li>
              <Message dictKey="disclose.personal.info.to.third.party.p1.li2" />
            </li>
            <li>
              <Message dictKey="disclose.personal.info.to.third.party.p1.li3" />
            </li>
            <li>
              <Message dictKey="disclose.personal.info.to.third.party.p1.li4" />
            </li>
            <li>
              <Message dictKey="disclose.personal.info.to.third.party.p1.li5" />
            </li>
            <li>
              <Message dictKey="disclose.personal.info.to.third.party.p1.li6" />
            </li>
            <li>
              <Message dictKey="disclose.personal.info.to.third.party.p1.li7" />
            </li>
          </ul>
          <h3>
            <Message dictKey="international.transfer.personal.info" />
          </h3>
          <p>
            <Message dictKey="international.transfer.personal.info.p1" />
          </p>
          <h3>
            <Message dictKey="right.controlling.personal.info" />
          </h3>
          <p>
            <Message dictKey="right.controlling.personal.info.p1" />
          </p>
          <p>
            <Message dictKey="right.controlling.personal.info.p2" />
          </p>
          <p>
            <Message dictKey="right.controlling.personal.info.p3" />
          </p>
          <p>
            <Message dictKey="right.controlling.personal.info.p4" />
          </p>
          <p>
            <Message dictKey="right.controlling.personal.info.p5" />
          </p>
          <h3>
            <Message dictKey="use.of.cookies" />
          </h3>
          <p>
            <Message dictKey="use.of.cookies.p1" />
          </p>
          <h3>
            <Message dictKey="limits.of.policy" />
          </h3>
          <p>
            <Message dictKey="limits.of.policy.p1" />
          </p>
          <h3>
            <Message dictKey="changes.in.policy" />
          </h3>
          <p>
            <Message dictKey="changes.in.policy.p1" />
          </p>
          <p>
            <Message dictKey="changes.in.policy.p2" />
          </p>
          <h3>
            <Message dictKey="footer.contact.us" />
          </h3>
          <p>
            <Message dictKey="contact.us.p1" />
          </p>
          <p>
            <Message dictKey="zs.legal.team" />
            <br />
            <Message dictKey="legal.mail" />
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(PrivacyPolicy);
